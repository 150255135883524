import _ from "lodash";

const initialValue = {
  roomInfo: {}, // Tất cả vị trí ghế ngồi
  userInfo: {},
  userPlace: {}, //Đặt chổ
  gameStart: false, //Game bắt đâu /
  myTurn: {
    // Tới lượt mình
    status: false,
    option: null,
  },
  oldTurn: {
    // Lượt phía trước
    PositionCode: null,
    option: null,
  },
  presentTurn: {}, // Lượt hiện tại
  mySeat: {}, // Ghế của mình ngồi
  bet: {
    // Tiền của bản thân trên bàn chơi
    BetMoney: 0,
    CurrentBet: 0,
    TotalBet: 0,
  },
  totalBet: 0, // Tổng tiền của tất cả người chơi trên bàn chơi
  isCheck: false, // Đã chia bài xong cho tất cả người chơi => tới lượt check
  cardMain: [], // 5 lá bài chính
  winnerPlayer: [], // Những người thắng
  newCardReceived: {}, //Nhận bài mới để hiển thị animation phát lá bài tới vị trí người nhận
  betReverved: 0,
  showAllCard: false, // Kết thúc ván game => show tất cả bài người chơi
  optionToolbar: {
    mutate: false,
    chat: false,
    screen: 1,
  },
  camera: {
    cameraMain: 1,
    cameraMySeat: 1,
  },
  timeAction: null,
  reaction: [], // Cảm xúc người chơi
  chat: [],
  captureCard: {
    status: false,
    catureList: [],
  },
  newChat: 0,
  openChat: false,
};

export const roomReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "JOIN_ROOM": {
      state.userInfo = action.payload.userInfo;
      // state.betReverved = action.payload.userInfo.accountBalance;
      return { ...state };
    }
    case "TAKE_SEAT": {
      state.roomInfo = action.payload;
      state.userPlace = {
        userName: action.payload.userName,
        userPriceGame: action.payload.userPriceGame,
        sheetNumber: action.payload.sheetNumber,
      };
      return { ...state };
    }
    case "LEAVE_SEAT": {
      state.roomInfo = action.payload;
      state.mySeat = {};
      state.userPlace = {
        userName: action.payload.userName,
        userPriceGame: action.payload.userPriceGame,
        sheetNumber: action.payload.sheetNumber,
      };
      state.captureCard.status = false;
      state.myTurn = {
        status: false,
        option: null,
      };
      return { ...state };
    }
    case "LEAVE_ROOM": {
      state.roomInfo = action.payload;
      state.mySeat = {};
      state.userPlace = {};
      state.captureCard.status = false;
      state.myTurn = {
        status: false,
        option: null,
      };
      return { ...state };
    }
    case "UPDATE_ROOM": {
      state.mySeat = {};
      state.roomInfo = action.payload;
      return { ...state };
    }
    case "UPDATE_SEAT_NEXT_TURN": {
      let newRoomInfo = state.roomInfo;
      // console.log('state.roomInfo[action.payload.Client.PositionCode - 1]', state.roomInfo[action.payload.Client.PositionCode - 1])
      newRoomInfo[action.payload.Client.PositionCode - 1] = {
        ...state.roomInfo[action.payload.Client.PositionCode - 1],
        Player: {
          ...state.roomInfo[action.payload.Client.PositionCode - 1].Player,
          PriceOnTable:
            state.roomInfo[action.payload.Client.PositionCode - 1].Player
              .PriceOnTable - action.payload.Bet.BetMoney,
        },
        PositionStatus: action.payload.Client.PositionStatus,
        card: state.roomInfo[action.payload.Client.PositionCode - 1].card,
        turn: {
          option:
            action.payload.Bet.BetType === "AllIn"
              ? "All-in"
              : action.payload.Bet.BetType,
          BetMoney: action.payload.Bet.BetMoney,
        },
      };
      if (action.payload.Client.PositionCode === state.mySeat.PositionCode) {
        const newMySeat = {
          ...state.mySeat,
          turn: {
            option:
              action.payload.Bet.BetType === "AllIn"
                ? "All-in"
                : action.payload.Bet.BetType,
            BetMoney: action.payload.Bet.BetMoney,
          },
        };
        state.mySeat = newMySeat;
      }
      state.roomInfo = newRoomInfo;
      return { ...state };
    }
    case "UPDATE_SEAT_ON_ROOM": {
      if (!_.isEmpty(state.roomInfo)) {
        if (action.payload.Player) {
          const findExistPlayer = state.roomInfo?.findIndex(
            (seat) => seat.Player?.uid == action.payload.Player?.uid
          );
          if (findExistPlayer > -1) {
            state.roomInfo[findExistPlayer] = {
              ...state.roomInfo[findExistPlayer],
              Player: null,
              HaveClient: false,
            };
          }
          // }

          if (action.payload.mySeat) {
            state.mySeat = { ...action.payload, turn: {} };
          }

          if (!_.isEmpty(state.mySeat.Player)) {
            if (action.payload.Player.uid == state.mySeat.Player.uid) {
              state.mySeat = { ...action.payload, turn: {} };
            }
          }

          let newRoomInfo = state.roomInfo;
          newRoomInfo[action.payload.PositionCode - 1] = {
            ...action.payload,
            card: state.roomInfo[action.payload.PositionCode - 1].card,
            mySeat: state.roomInfo[action.payload.PositionCode - 1].mySeat,
          };
          state.roomInfo = newRoomInfo;
          if (action.payload.PositionCode == state.mySeat.PositionCode) {
            state.mySeat = { ...action.payload, turn: {} };
          }
        } else {
          // Này là trường hợp người chơi khác rời khỏi ghế
          if (_.isInteger(action.payload)) {
            state.roomInfo[action.payload - 1] = {
              ...state.roomInfo[action.payload - 1],
              Player: null,
              HaveClient: false,
            };
          }
        }
      }
      return { ...state };
      // }
    }
    case "UPDATE_BET_ON_SEAT": {
      if (action.payload.Client.PositionCode == state.mySeat.PositionCode) {
        state.bet.CurrentBet = action.payload.Bet.CurrentBet;
        state.mySeat.Player.PriceOnTable =
          state.mySeat.Player.PriceOnTable - action.payload.Bet.BetMoney;
      } else {
        state.oldTurn = {
          option: action.payload.Bet.BetType,
          PositionCode: action.payload.Client.PositionCode,
          BetMoney: action.payload.Bet.BetMoney,
        };
      }
      let totalBet = state.totalBet + action.payload.Bet?.BetMoney;
      state.totalBet = totalBet;
      if (action.payload.option != "Fold" && action.payload.option != "Check") {
        state.bet.TotalBet = action.payload.Bet?.TotalBet;
      }
      // let indexSeat = state.roomInfo?.findIndex(
      //   (room) => room.PositionCode == action.payload.Client.PositionCode
      // );
      // if (indexSeat > -1) {
      //   console.log('chạy lại bằng 2')
      //   state.roomInfo[action.payload.Client.PositionCode - 1].TotalBet =
      //     action.payload.Bet.CurrentBet;
      // }
      state.bet.BetMoney = 0;
      return { ...state };
    }
    case "UPDATE_FIRST_BET_ON_SEAT": {
      if (action.payload.Client.PositionCode == state.mySeat.PositionCode) {
        switch (action.payload.Client.PositionType) {
          case 2:
          case 3: {
            state.bet.CurrentBet = action.payload.Bet?.BetMoney;
            state.mySeat.Player.PriceOnTable =
              state.mySeat.Player.PriceOnTable - action.payload.Bet?.BetMoney;
            state.mySeat.TotalBet = action.payload.Bet.TotalBet;
            break;
          }
          default:
            break;
        }
        // state.bet.CurrentBet = action.payload.Bet?.BetMoney;
        // state.mySeat.Player.PriceOnTable =
        //   state.mySeat.Player.PriceOnTable - action.payload.Bet?.BetMoney;
      }
      switch (action.payload.Client.PositionType) {
        case 2:
        case 3: {
          state.totalBet = state.totalBet + action.payload.Bet?.BetMoney;
          let indexSeat = state.roomInfo?.findIndex(
            (room) => room.PositionCode == action.payload.Client.PositionCode
          );
          if (indexSeat > -1) {
            state.roomInfo[action.payload.Client.PositionCode - 1].TotalBet =
              action.payload.Bet.CurrentBet;
          }
          break;
        }
        default:
          break;
      }
      // state.totalBet = state.totalBet + action.payload.Bet?.BetMoney;
      let newRoomInfo = state.roomInfo;
      newRoomInfo[action.payload.Client.PositionCode - 1] = {
        ...state.roomInfo[action.payload.Client.PositionCode - 1],
        Player: {
          ...state.roomInfo[action.payload.Client.PositionCode - 1].Player,
          PriceOnTable:
            state.roomInfo[action.payload.Client.PositionCode - 1].Player
              .PriceOnTable - action.payload.Bet.BetMoney,
        },
        card: state.roomInfo[action.payload.Client.PositionCode - 1].card,
        mySeat: {
          ...state.roomInfo[action.payload.Client.PositionCode - 1].mySeat,
          turn: {},
        },
      };
      state.roomInfo = newRoomInfo;
      state.bet.TotalBet = action.payload.Bet?.TotalBet;
      state.bet.BetMoney = 0;
      return { ...state };
    }
    case "UPDATE_SEAT_AT_POSITION": {
      let payload = action.payload;
      let newStateInfo = state.roomInfo[payload.PositionCode - 1];
      if (payload.changePlayerInfo) {
        newStateInfo.Player = payload.Player;
        state.roomInfo[payload.PositionCode - 1] = newStateInfo;
        if (payload.PositionCode === state.mySeat?.PositionCode) {
          state.mySeat.Player.nickName = payload.Player.nickName;
          state.userInfo.nickName = payload.Player.nickName;
        }
      } else {
        newStateInfo.PositionType = payload.PositionType;
        newStateInfo.PositionStatus = payload.PositionStatus;
        newStateInfo.Player = payload.Player;
        newStateInfo.turn = payload.turn;
      }
      return { ...state };
    }
    case "RECEIVE_CARD_ON_SEAT": {
      let payload = action.payload;
      if (payload.ShowCardEndGame) {
        if (
          payload.PositionCode != state.mySeat.PositionCode &&
          !state.showAllCard
        ) {
          let seat = state.roomInfo[payload.PositionCode - 1];
          state.newCardReceived = payload;
          seat.card.push(payload.CardSetupInfo);
        }
      } else {
        if (state.isCheck) {
          let cardMain = state.cardMain;
          cardMain.push(payload.CardSetupInfo);
        } else {
          if (payload.CardType >= 2) {
            let cardMain = state.cardMain;
            cardMain.push(payload.CardSetupInfo);
          } else {
            let seat = state.roomInfo[payload.PositionCode - 1];
            state.newCardReceived = payload;
            seat.card.push(payload.CardSetupInfo);
          }
        }
      }

      return { ...state };
    }
    case "START_GAME": {
      state.gameStart = true;
      state.showAllCard = false;
      state.presentTurn = {};
      state.cardMain = [];
      state.captureCard.status = false;
      state.winnerPlayer = [];
      let newRoomInfo = state.roomInfo;
      // state.bet = {
      //   BetMoney: 0,
      //   CurrentBet: 0,
      //   TotalBet: 0,
      // }
      state.roomInfo = newRoomInfo.map((room, index) => {
        return {
          ...room,
          PositionStatus: 1,
          PositionType: 0,
          card: [],
          turn: {},
          TotalBet: 0,
        };
      });
      let newMySeat = state.mySeat;
      state.mySeat = { ...newMySeat, turn: {} };
      return { ...state };
    }
    case "EXIT_ROOM": {
      state.presentTurn = {};
      state.gameStart = false;
      state.totalBet = 0;
      state.isCheck = false;
      state.cardMain = [];
      state.bet = {
        BetMoney: 0,
        CurrentBet: 0,
        TotalBet: 0,
      };
      state.winnerPlayer = [];
      state.captureCard.status = false;
      state.myTurn.status = false;
      state.chat = [];
      state.reaction = [];
      return { ...state };
    }
    case "END_GAME": {
      let winner = [];
      state.roomInfo?.forEach((room) => {
        for (let i of action.payload) {
          if (i.PlayerCode == room.PositionCode) {
            winner.push({
              ...room,
              moneyWinner: i.MoneyWinner,
              isWinner: i.IsWinner,
            });
          }
        }
      });
      state.showAllCard = true;
      state.winnerPlayer = winner;
      state.captureCard.status = false;
      state.totalBet = 0;
      state.isCheck = false;
      state.gameStart = false;
      state.bet = {
        BetMoney: 0,
        CurrentBet: 0,
        TotalBet: 0,
      };
      state.myTurn.status = false;
      state.myTurn.option = null;
      return { ...state };
    }
    case "SHOW_PLAYER_HAND_RANK": {
      const { payload } = action;
      const winnerHandRank = payload?.ListHandRanks?.find(
        (winner, index) => winner.Rank == 1
      );
      let newWinnerPlayer = state.winnerPlayer;
      newWinnerPlayer.forEach((winner, index) => {
        winner.HandRank = {
          handRankType: payload?.TypeWinner,
          handRankName:
            payload.TypeWinner == 1
              ? winnerHandRank.HandRank
              : "Because the other players have folded.",
        };
      });
      state.winnerPlayer = newWinnerPlayer;
      return { ...state };
    }
    case "CLEAR_MY_TURN": {
      state.myTurn.status = false;
      state.myTurn.option = null;
      return { ...state };
    }
    case "RESET_GAME": {
      let newRoomInfo = state.roomInfo;
      state.roomInfo = newRoomInfo.map((room, index) => {
        return {
          ...room,
          turn: {},
          card: [],
          TotalBet: 0,
          turn: {},
          Player: {
            ...room.Player,
            TotalMoney: room?.Player?.TotalAmount - room?.Player?.PriceOnTable,
          },
        };
      });
      state.presentTurn = {};
      state.gameStart = false;
      state.totalBet = 0;
      state.isCheck = false;
      state.captureCard.status = false;
      state.bet = {
        BetMoney: 0,
        CurrentBet: 0,
        TotalBet: 0,
      };
      state.myTurn.status = false;
      state.myTurn.option = null;
      return { ...state };
    }
    case "CANCEL_GAME": {
      state.presentTurn = {};
      state.gameStart = false;
      state.totalBet = 0;
      state.isCheck = false;
      state.showAllCard = false;
      state.cardMain = [];
      state.captureCard.status = false;
      state.bet = {
        BetMoney: 0,
        CurrentBet: 0,
        TotalBet: 0,
      };
      state.myTurn.status = false;
      state.myTurn.option = null;
      action.payload?.forEach((seat) => {
        if (seat.PositionCode == state.mySeat.PositionCode) {
          state.mySeat.Player = seat.Player;
        }
        state.roomInfo[seat.PositionCode - 1] = {
          ...state.roomInfo[seat.PositionCode - 1],
          card: [],
          TotalBet: 0,
          Player: {
            ...seat.Player,
            PriceOnTable: seat.MoneyRollBack,
          },
          turn: {},
        };
      });
      return { ...state };
    }
    case "UPDATE_SHOW_ALL_CARD": {
      state.showAllCard = true;
      return { ...state };
    }
    case "UPDATE_CHECK_STATUS": {
      state.isCheck = action.payload;
      return { ...state };
    }
    case "CHANGE_TURN": {
      state.myTurn.status =
        action.payload.PositionCode == state.mySeat?.PositionCode;
      state.presentTurn = action.payload;
      return { ...state };
    }
    case "UPDATE_MY_TURN": {
      state.myTurn.option = action.payload.option;
      state.myTurn.status = false;
      return { ...state };
    }
    case "UPDATE_CLIENT_ON_SEAT": {
      let newClientIndex = state.roomInfo.findIndex(
        (client) => client.PositionCode == action.payload.Client.PositionCode
      );
      if (action.payload.Bet) {
        state.roomInfo[newClientIndex].Player.PriceOnTable =
          state.roomInfo[newClientIndex]?.Player?.PriceOnTable +
          action.payload.Bet.BetMoney;
      }
      if (action.payload.Client.PositionCode == state.mySeat.PositionCode) {
        state.mySeat.Player.PriceOnTable =
          state.mySeat.Player.PriceOnTable + action.payload.Bet.BetMoney;
      }
      state.roomInfo[newClientIndex].PositionStatus =
        action.payload.Client.PositionStatus;
      return { ...state };
    }
    case "UPDATE_BET_ON_SEAT_RESERVED": {
      state.betReverved = state.userInfo.accountBalance - action.payload;
      return { ...state };
    }
    case "UPDATE_USER_INFO_ON_ROOM": {
      state.userInfo.accountBalance = action.payload.accountBalance;
      return { ...state };
    }
    case "UPDATE_OPTION_TOOLBAR": {
      state.optionToolbar = { ...state.optionToolbar, ...action.payload };
      return { ...state };
    }
    case "UPDATE_MY_SEAT": {
      state.roomInfo[action.payload.PositionCode - 1].Player =
        action.payload.Player;
      state.roomInfo[action.payload.PositionCode - 1].Player.TotalMoney =
        action.payload.Player.TotalAmount - action.payload.Player.PriceOnTable;
      if (action.payload.PositionCode == state.mySeat.PositionCode) {
        let newMySeat = {
          ...state.mySeat,
          Player: {
            ...action.payload.Player,
            TotalMoney:
              action.payload.Player.TotalAmount -
              action.payload.Player.PriceOnTable,
          },
          PositionCode: action.payload.PositionCode,
        };
        state.mySeat = newMySeat;
      }
      return { ...state };
    }
    case "RECONNECT_GAME": {
      const payload = action.payload;
      state.gameStart = true;
      state.isCheck = [3, 4, 5].includes(payload.RoundStatus);
      state.totalBet = payload.ThePot;
      state.bet = {
        ...state.bet,
        CurrentBet: payload.CurrentBet,
        TotalBet: payload.TotalBet,
      };
      let a = [];
      for (let i = 0; i < 7; i++) {
        a.push({
          PositionType: 0,
          PositionStatus: 0,
          PositionCode: i + 1,
          HaveClient: false,
          TotalBet: 0,
          Player: null,
          card: [],
          turn: {},
        });
      }
      state.roomInfo = a;
      payload?.ListDataSeatInfo.forEach((seat, index) => {
        state.roomInfo[seat.PositionCode - 1] = {
          ...seat,
          card: [],
          turn: {},
          Player: {
            ...seat.Player,
            TotalMoney: !_.isEmpty(seat.Player)
              ? seat?.Player?.TotalAmount -
                seat?.Player?.PriceOnTable -
                seat?.TotalBet
              : 0,
          },
        };
      });
      state.mySeat = { ...state.roomInfo[payload.YourPosCode - 1], turn: {} };
      if (payload.TurnCurrentPlayerCode > 0 && payload.TimeAction > 0) {
        state.presentTurn = state.roomInfo[payload.TurnCurrentPlayerCode - 1];
        state.myTurn.status =
          payload.TurnCurrentPlayerCode === state.mySeat?.PositionCode;
        state.timeAction = payload.TimeAction;
      }

      Object.keys(payload?.ListCardPlayer).forEach((key) => {
        payload?.ListCardPlayer[key].forEach((card, i) => {
          let seat = state.roomInfo[key - 1];

          state.newCardReceived = payload;
          seat.card.push(card);
        });
      });

      if (payload.CardFlop1.CardCode != "None") {
        state.cardMain.push(payload.CardFlop1);
        if (payload.CardFlop2.CardCode != "None") {
          state.cardMain.push(payload.CardFlop2);
          if (payload.CardFlop3.CardCode != "None") {
            state.cardMain.push(payload.CardFlop3);
            if (payload.CardTurn.CardCode != "None") {
              state.cardMain.push(payload.CardTurn);
              if (payload.CardRiver.CardCode != "None") {
                state.cardMain.push(payload.CardRiver);
              }
            }
          }
        }
      }
      let winner = [];
      if (!_.isEmpty(payload.DataPlayerWinner)) {
        state.roomInfo?.forEach((room) => {
          for (let i of payload.DataPlayerWinner) {
            if (i.PlayerCode == room.PositionCode) {
              winner.push({
                ...room,
                moneyWinner: i.MoneyWinner,
                isWinner: i.IsWinner,
              });
            }
          }
        });
        state.showAllCard = true;
        state.winnerPlayer = winner;
      }
      return { ...state };
    }
    case "CLEAR_TURN_ROOM": {
      if (!_.isEmpty(state.roomInfo)) {
        let newRoomInfo = state.roomInfo;
        state.roomInfo = newRoomInfo.map((room, index) => {
          return { ...room, turn: {} };
        });
      }
      return { ...state };
    }
    case "NEW_ROUND": {
      state.presentTurn = {};
      return { ...state };
    }
    case "UPDATE_REACTION": {
      let payload = action.payload;
      let newListReaction = state.reaction;
      switch (payload.status) {
        case "remove": {
          let newFilter = newListReaction;
          newListReaction = newFilter.filter(
            (reaction, index) =>
              reaction.SeatPosCode != payload.SeatPosCode ||
              reaction.EmotionId != payload.EmotionId
          );
          break;
        }
        case "insert": {
          let isExsits = null;
          if (state.reaction.length > 0) {
            isExsits = newListReaction?.findIndex(
              (reaction) => reaction.SeatPosCode == payload.SeatPosCode
            );
          }
          if (isExsits > -1 && isExsits != null) {
            newListReaction.splice(isExsits, 1);
          }
          newListReaction.push(payload);
          break;
        }
        default:
          break;
      }
      state.reaction = newListReaction;
      return { ...state };
    }
    case "UPDATE_CHAT_ON_ROOM": {
      const { Status = true } = action.payload;
      switch (Status) {
        case true: {
          let newListChat = state.chat;
          if (action.payload.Uid !== state.userInfo?.uid) {
            if (!state.openChat) {
              let newChatNumber = state.newChat + 1;
              state.newChat = newChatNumber;
            }
          }
          newListChat.push(action.payload);
          state.chat = newListChat;
          break;
        }
        case false: {
          break;
        }
        default:
          break;
      }
      return { ...state };
    }
    case "UPDATE_SCREEN_MY_SEAT": {
      state.camera.cameraMySeat = action.payload;
      return { ...state };
    }
    case "UPDATE_CAPTURE_CARD": {
      state.captureCard.status = action.payload.status;
      return { ...state };
    }
    case "UPDATE_OPEN_CHAT": {
      state.openChat = action.payload;
      if (!action.payload) state.newChat = 0;
      return { ...state };
    }
    default:
      return { ...state };
  }
};
