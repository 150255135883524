import { Empty } from "antd";
import React, { useContext, useEffect } from "react";
import Room from "./components/room";
import useGetRoom from "main/admin/roomMgt/hook/useGetRoom";
import { useFilters } from "share/function";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import ToolbarHome from "./components/toolbarHome";
import { SocketUserProvider } from "fuse/layout-user/layout-master";
import toast from "react-hot-toast";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { changeDataRoomByID, updateRoom } from "redux/action/homeAction";
import ProfileSetting from "./components/profileSetting";
import ChangePassword from "./components/changePassword";
import { FormProvider, useForm } from "react-hook-form";

const Home = () => {
  const { socket } = useContext(SocketUserProvider);
  const defaultValues = {
    roomPrice: 1,
    dealerStatus: 99,
  };

  const dispatch = useDispatch();

  const { count, setCount, filters, onFilter } = useFilters(defaultValues);

  const { data, isLoading, isFetching, fetch } = useGetRoom(filters);

  const { roomData } = useSelector((state) => state.home);

  const method = useForm({
    mode: 'onSubmit',
    defaultValues
  });

  // useLoadingEffect(isLoading);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      dispatch(updateRoom(data?.data));
    }
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = async (evt) => {
        const result = JSON.parse(evt.data);
        switch (result.FunctionCode) {
          case "refresh_room": {
            await fetch();
            break;
          }
          case "sync_update_room": {
            const {Data} = result;
            dispatch(changeDataRoomByID(Data))
            break;
          }
          default:
            break;
        }
      };
    }
  }, [socket]);

  useEffect(() => {
    function hideError(e) {
      if (
        e.message ===
          "ResizeObserver loop completed with undelivered notifications." ||
        e.message ===
          "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD" ||
        e.message ===
          "play() failed because the user didn't interact with the document first."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
  }, []);

  const handleSearch = (data) => {
    onFilter({
      roomPrice: parseInt(data.roomPrice),
      dealerStatus: parseInt(data.dealerStatus),
    })
  }

  return (
    <FormProvider {...method}>
      <div className="flex items-center justify-between p-[10px] lg:p-[20px]">
        <img
          src="/images/livegame3.png"
          className="w-[120px] lg:w-[250px] 2xl:w-[350px] h-fit object-fit object-left"
        />
        <ToolbarHome handleSearch={handleSearch}/>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[10px] lg:gap-[30px] 2xl:gap-[40px] p-[10px] lg:p-[20px] 2xl:p-[60px]">
        {roomData?.length > 0 ? (
          roomData?.map((room, index) => {
            return <Room data={room} key={index} />;
          })
        ) : (
          <Empty />
        )}
      </div>
      <ProfileSetting/>
      <ChangePassword/>
    </FormProvider>
  );
};

export default Home;
