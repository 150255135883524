import { Button } from "antd";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const FormChat = ({ socket }) => {
  const { mySeat } = useSelector((state) => state.room);

  const { userInfo } = useSelector((state) => state.home);

  const method = useForm({
    mode: "onSubmit",
    defaultValues: {
      content: "",
    },
  });

  const { control, handleSubmit, register, setValue } = method;

  const handleSubmitForm = (data) => {
    socket.send(
      JSON.stringify({
        FunctionCode: "SendMessage",
        FFunctionCode: "",
        Data: {
          Player: {
            uid: userInfo?.uid ?? mySeat?.Player?.uid,
            PlayerType: mySeat?.Player?.PlayerType ?? 1,
            PriceOnTable: mySeat?.Player?.PriceOnTable ?? 0,
            TotalAmount: mySeat?.Player?.TotalAmount ?? 0,
            nickName: userInfo?.nickName ?? mySeat?.Player?.nickName, 
            userName: userInfo?.userName ?? mySeat?.Player?.userName,
          },
          Message: data.content,
        },
      })
    );
    setValue("content", "");
    setTimeout(() => {
      const showChatWrap = document.querySelector("#showChatWrap");
      showChatWrap.scrollTop = showChatWrap?.scrollHeight;
    }, 300);
  };

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className="w-full flex items-center"
      >
        <input
          className="rounded-[5px] outline-none border-[1px] border-solid border-[#c2c2c2] lg:p-[5px_7px] 2xl:p-[10px_15px] w-[calc(100%_-_50px)] box-border"
          {...register("content")}
          placeholder="Nhập vào nội dung..."
          autoComplete="off"
        />
        <Button
          className="h-full lg:p-[5px_7px] ml-[5px] bg-[#13563B]"
          type="primary"
          htmlType="sumit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#fff"
            className="lg:size-4 2xl:size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
        </Button>
      </form>
    </FormProvider>
  );
};

export default FormChat;
