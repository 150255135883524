import React, { memo, useEffect, useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getSplitPointSeat,
  STYLES_SEAT_COMPONENT,
  STYLES_USER_CARD_SEAT,
} from "../constant";
import UserCard from "./userCard";
import UserJoin from "./userJoin";
import { PositionStatus } from "share/helperCommon";
import { getImageCardByCode } from "share/function";
import OptionTurn from "./optionTurn";
import { updateScreenMySeat } from "redux/action/roomAction";

const SeatCard = ({ socket, data, timeGameStart, toggleBuyIn }) => {
  const {
    gameStart,
    presentTurn,
    showAllCard,
    mySeat,
    myTurn,
    camera: { cameraMySeat },
  } = useSelector((state) => state.room);

  const { music } = useSelector((state) => state.toolbar);

  useEffect(() => {
    if (myTurn.status) {
      music.MyTurn.play();
    }
  }, [myTurn.status]);

  const renderCard = () => {
    let indexSeat = data?.findIndex(
      (seat) => seat.PositionCode === mySeat?.PositionCode
    );
    if (indexSeat > -1 && indexSeat != null) {
      const dataRoom = data[indexSeat];
      return ["", ""]?.map((card, index) => {
        return (
          <div
            className="lg:w-[70px] 2xl:w-[95px] lg:h-[98px] 2xl:h-[140px] border-dashed border-[#c1c1c1] border-[1px] rounded-[10px] flex items-center justify-center"
            key={index}
          >
            {dataRoom?.PositionStatus == PositionStatus.Folded && showAllCard ? (
              <div className="relative w-fit h-fit flex">
                <img
                  src="/images/52card/lungbai.png"
                  className="lg:w-[65px] 2xl:w-[83px] mx-[2px] z-[3]"
                />
              </div>
            ) : (
              !_.isEmpty(dataRoom?.card) &&
              dataRoom?.card[index]?.CardCode != "None" &&
              dataRoom?.card[index]?.CardCode != "FaceDown" &&
              !_.isEmpty(dataRoom?.card[index]?.CardCode) && (
                <img
                  src={getImageCardByCode(dataRoom?.card[index]?.CardCode)}
                  className="lg:w-[65px] 2xl:w-[85px] mx-[2px] z-[3] eee"
                />
              )
            )}
          </div>
        );
      });
    }
  };

  const renderSeat = () => {
    if (_.isEmpty(data)) return <div />;
    return data?.map((item, index) => {
      return (
        <div
          className={`${STYLES_SEAT_COMPONENT[index]} lg:h-[75.25px] 2xl:h-[112.8px]`}
          key={index}
        >
          <div
            className={`flex items-center h-full ${
              index < 4 ? "flex-row-reverse" : ""
            }`}
          >
            <UserJoin
              data={item}
              sheetNumber={item?.PositionCode - 1}
              setOpen={toggleBuyIn}
              gameStart={gameStart}
              socket={socket}
              turn={presentTurn?.PositionCode == item.PositionCode}
              timeGameStart={timeGameStart}
            />
            <div className={`${STYLES_USER_CARD_SEAT[index]}`}>
              {(gameStart || showAllCard) && (
                <UserCard index={index} dataRoom={item} />
              )}
            </div>
          </div>
        </div>
      );
      // }
    });
  };

  const handleChangeMySeatScreen = () => {
    // dispatch(updateScreenMySeat(cameraMySeat == 1 ? 2 : 1));
    socket.send(
      JSON.stringify({
        FunctionCode: "CaptureCard",
      })
    );
  };
  return (
    <>
      {renderSeat()}
      {!_.isEmpty(mySeat) && (
        <div
          className="flex flex-col justify-between absolute lg:bottom-[20px] left-[50%] lg:w-[230px] lg:h-[130px] 2xl:w-[350px] 2xl:h-[200px] z-[4] rounded-[10px]"
          style={{ transform: "translateX(-50%)" }}
        >
          <div className="flex justify-center">
            <div className="grid grid-cols-2 lg:gap-[5px] 2xl:gap-[10px] relative">
              {renderCard()}
              {((showAllCard &&
                data[
                  data?.findIndex(
                    (seat) => seat.PositionCode === mySeat?.PositionCode
                  )
                ]?.PositionStatus == PositionStatus.Folded) ||
                data[
                  data?.findIndex(
                    (seat) => seat.PositionCode === mySeat?.PositionCode
                  )
                ]?.PositionStatus == PositionStatus.Folded) && (
                <div className="absolute top-0 left-0 w-full h-full bg-[#ffffff6c] flex items-center justify-center font-bold lg:text-[23px] 2xl:text-[30px] text-[#C83737] rounded-[10px] z-[4]">
                  FOLD
                </div>
              )}
            </div>
          </div>
          <div className="lg:h-[28px] 2xl:h-[42px] w-full flex items-center">
            {gameStart && myTurn?.status && (
              <OptionTurn socket={socket} dataRoom={data} />
            )}
          </div>
          {/* {gameStart && ( */}
          <button
            className="border-none outline-none absolute lg:bottom-[30px] 2xl:bottom-[65px] lg:right-[0px] 2xl:right-[10px] duration-300 rounded-[50%] lg:w-[35px] lg:h-[35px] 2xl:w-[40px] 2xl:h-[40px] bg-[#FFDF00]"
            onClick={handleChangeMySeatScreen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000"
              className="lg:size-5 2xl:size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
              />
            </svg>
          </button>
          {/* )} */}
        </div>
      )}
    </>
  );
};

export default memo(SeatCard);
