let initialValue = {
  music: {
    Main: new Audio("/sound/sound5.mp3"),
    Winner: new Audio("/sound/WINNER.mp3"),
    Call: new Audio("/sound/Call_ms.mp3"),
    Check: new Audio("/sound/Check_ms.mp3"),
    CFold: new Audio("/sound/Fold_ms.mp3"),
    Raise: new Audio("/sound/Raise_ms.mp3"),
    StartGame: new Audio("/sound/startGame.mp3"),
    Throwing: new Audio("/sound/throwingCards.mp3"),
    AllIn: new Audio("/sound/AllIn_ms.mp3"),
    MyTurn: new Audio("/sound/yourturn.mp3"),
    VolumeMain: 1,
    VolumeEffect: 50,
  },
  cameraStream: "Cam1",
};

export const toolbarReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "updateMusic": {
      let payload = action.payload;
      const { typeMusic, status } = payload;
      switch (typeMusic) {
        case "Main": {
          if (status) {
          }
          break;
        }
        default:
          break;
      }
      break;
    }
    case "UPDATE_CAMERA_STREAM": {
      state.cameraStream = action.payload;
      return { ...state };
    }
    case "UPDATE_VOLUME": {
      const {type, volume} = action.payload;
      switch(type) {
        case "main": {
          state.music.VolumeMain = volume;
          break;
        }
        case "effect": {
          state.music.VolumeEffect = volume;
          break;
        }
        default: break;
      }
      return {...state};
    }
    default:
      return { ...state };
  }
};
