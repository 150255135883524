import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Raise from "./components/Raise";
import Call from "./components/Call";
import Fold from "./components/Fold";
import Check from "./components/Check";
import { updateMyTurn } from "redux/action/roomAction";

const OptionTurn = ({ socket, dataRoom }) => {
  const { roomInfo, userPlace, gameStart, myTurn, presentTurn, bet, mySeat } =
    useSelector((state) => state.room);

  const dispatch = useDispatch();

  const handleFold = () => {
    dispatch(updateMyTurn({
      option: "Fold"
    }))
    socket.send(
      JSON.stringify({
        FunctionCode: "Fold",
        FFunctionCode: "",
        Data: {
          Client: {
            PositionType: presentTurn.PositionType,
            PositionCode: presentTurn.PositionCode,
            PositionStatus: presentTurn.PositionStatus,
          },
          Bet: {
            BetMoney: 0,
            CurrentBet: 0,
            TotalBet: 0,
          },
        },
        // Data: null
      })
    );
  };
  const handleCheck = () => {
    dispatch(updateMyTurn({
      option: "Check"
    }))
    socket.send(
      JSON.stringify({
        FunctionCode: "Check",
        FFunctionCode: "",
        Data: {
          Client: {
            PositionType: presentTurn.PositionType,
            PositionCode: presentTurn.PositionCode,
            PositionStatus: presentTurn.PositionStatus,
          },
          Bet: {
            BetMoney: 0,
            CurrentBet: 0,
            TotalBet: 0,
          },
        },
        // Data: null
      })
    );
  };
  const handleRaise = (moneyBet) => {
    dispatch(updateMyTurn({
      option: "Raise"
    }))
    socket.send(
      JSON.stringify({
        FunctionCode: "Raise",
        FFunctionCode: "",
        Data: {
          Client: {
            PositionType: presentTurn.PositionType,
            PositionCode: presentTurn.PositionCode,
            PositionStatus: presentTurn.PositionStatus,
          },
          Bet: {
            BetMoney: parseFloat(moneyBet),
            CurrentBet: 0,
            TotalBet: 0,
          },
        },
        // Data: null
      })
    );
  };

  const handleCall = () => {
    if(mySeat?.Player?.PriceOnTable >  (bet.TotalBet - bet.CurrentBet)) {
      dispatch(updateMyTurn({
        option: "Call"
      }))
      socket.send(
        JSON.stringify({
          FunctionCode: "Call",
          FFunctionCode: "",
          Data: {
            Client: {
              PositionType: presentTurn.PositionType,
              PositionCode: presentTurn.PositionCode,
              PositionStatus: presentTurn.PositionStatus,
            },
            Bet: {
              BetMoney: 0,
              CurrentBet: 0,
              TotalBet: 0,
            },
          },
        })
      );
    } else {
      handleAllIn();
    }
  }; 

  const handleAllIn = () => {
    dispatch(updateMyTurn({
      option: "All-IN"
    }))
    socket.send(
      JSON.stringify({
        FunctionCode: "AllIn",
        FFunctionCode: "",
        Data: {
          Client: {
            PositionType: presentTurn.PositionType,
            PositionCode: presentTurn.PositionCode,
            PositionStatus: presentTurn.PositionStatus,
          },
          Bet: {
            BetMoney: 0,
            CurrentBet: 0,
            TotalBet: 0,
          },
        },
        // Data: null
      })
    );
  }

  return (
    <div className="w-full flex justify-evenly z-[2]">
      <Fold handleFold={handleFold} />
      {bet.CurrentBet == bet.TotalBet ? (
        <Check handleCheck={handleCheck} />
      ) : (
        <Call handleCall={handleCall} />
      )}
      {/* <Call handleCall={handleCall} />
      <Check handleCall={()=> {}}/> */}
      {
        mySeat?.Player?.PriceOnTable > (bet.TotalBet - bet.CurrentBet)
        &&
        <Raise handleRaise={handleRaise} handleAllIn={handleAllIn}/>
      }
    </div>
  );
};

export default OptionTurn;
