import React from "react";
import { popupIdSignUp } from "../constant";
import { CustomPopup } from "share/components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closePopup } from "redux/action/custom-popup/customPopupAction";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    navigate("/login");
  };

  const handleOk = () => {
    dispatch(closePopup({id: popupIdSignUp.confirmEmail}));
    navigate("/login");
  }

  return (
    <CustomPopup
      id={popupIdSignUp.confirmEmail}
      style={{ padding: 0 }}
      closeIcon={false}
      className="no-padding"
      onCancel={handleCancel}
    >
      <div
        className="rounded-[20px] border-[2px] border-solid border-[#FFDF00] p-[20px_40px] flex flex-col items-center w-[40vw] h-fit"
        style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
      >
        <img
          src="/images/mail.png"
          className="w-[80px] lg:w-[140px] 2xl:w-[200px] h-fit"
        />
        <div className="text-center text-[12px] lg:text-[15px] 2xl:text-[18px] text-white grid grid-cols-1 gap-[20px]">
          <div>---</div>
          <p>Thank you for registering with Livegame!</p>
          <p>
            To complete the activation of your account, please check your email
            inbox for a message from us. Inside the email, you will find a link.
            Click on this link to activate your account.
          </p>
          <p>
            If you do not see the email in your inbox, please check your spam or
            junk folder.
          </p>
        </div>
        <button className="mt-[30px] lg:p-[15px_7px] 2xl:p-[10px] min-w-[100px] rounded-[20px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]" onClick={handleOk}>
          OK
        </button>
      </div>
    </CustomPopup>
  );
};

export default ConfirmEmail;
