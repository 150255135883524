import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import * as io from "socket.io-client";

export const SocketUserProvider = createContext(null);

const LayoutUserMaster = () => {
  const navigate = useNavigate();

  const [isBlocked, setIsBlocked] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // const socketAPI = new WebSocket("ws://192.168.100.66:3002/socket-api");

    const socketAPI = new WebSocket("wss://api.clark.thlone.vn/socket-api/");
    setSocket(socketAPI);

    socketAPI.onopen = () => {
      const auth = {
        token: localStorage.getItem("jwt") || null,
      };

      socketAPI.send(
        JSON.stringify({ FunctionCode: "authorization", Data: auth })
      );

      setInterval(() => {
        if (socketAPI.readyState === WebSocket.OPEN) {
          socketAPI.send(JSON.stringify({ FunctionCode: "ping", Data: {} }));
        }
      }, 30000);
    };

    socketAPI.onmessage = async (evt) => {
      const result = JSON.parse(evt.data);
      switch (result.FunctionCode) {
        case "account_is_blocked": {
          setIsBlocked(true);
          break;
        }
        default:
          break;
      }
    };

    socketAPI.onclose = async (e) => {
      console.log("close rùi nè");
    };

    socketAPI.onerror = async () => {
      console.log("error rùi nè");
    };

    return () => {
      setIsBlocked(false);
    };
  }, []);

  return (
    <SocketUserProvider.Provider value={{ socket: socket }}>
      <Outlet />
      {isBlocked && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#00000088] flex items-center justify-center z-[1999]">
          <div className="bg-white rounded-[20px] lg:p-[15px] 2xl:p-[20px]">
            <h5 className="text-[#C83737] lg:text-[20px] 2xl:text-[23px]">
              Tài khoản của bạn đã bị vô hiệu hóa
            </h5>
            <p className="my-[20px] text-center">
              Hiệu lực từ ngày {moment(new Date()).format("YYYY-MM-DD")} đến
              ngày ...{" "}
            </p>
            <div className="flex items-center justify-center">
              <button
                className="lg:p-[15px_7px] 2xl:p-[10px] min-w-[50px] rounded-[10px] border-none outline-none bg-[#13563B] text-[#fff] flex items-center justify-center ml-[10px]"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Nói gì nữa giờ
              </button>
            </div>
          </div>
        </div>
      )}
    </SocketUserProvider.Provider>
  );
};

export default LayoutUserMaster;
