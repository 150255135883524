import React, { useState } from "react";

import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  const handleChooseNav = (menu) => {
    localStorage.setItem("menuActived", JSON.stringify(menu))
    navigate(menu.menuUrl)
  }

  const menuList = [
    {
      key: "1",
      icon: <img src="/images/icon10.png" className="w-[30px] h-fit" />,
      label: "사용자관리",
      children: [
        {
          key: "2",
          onClick: () => handleChooseNav({menuUrl: "userMgt", menuTitle: "사용자 리스트"}),
          icon: (
            <img src="/images/livegame.png" className="w-[25px] h-[25px]" />
          ),
          label: "사용자 리스트",
        },
        {
          key: "9",
          onClick: () => handleChooseNav({menuUrl: "userLogsMgt", menuTitle: "사용자 로그 관리"}),
          icon: (
            <img src="/images/livegame.png" className="w-[25px] h-[25px]" />
          ),
          label: "사용자 로그 관리",
        },
        {
          key: "3",
          onClick: () => handleChooseNav({menuUrl: "registerUser", menuTitle: "신청관리"}),
          icon: (
            <img src="/images/livegame.png" className="w-[25px] h-[25px]" />
          ),
          label: "신청관리",
        },
      ],
    },
    {
      key: "4",
      icon: <img src="/images/icon11.png" className="w-[30px] h-fit" />,
      label: "매니저관리",
      children: [
        {
          key: "5",
          icon: (
            <img src="/images/livegame.png" className="w-[25px] h-[25px]" />
          ),
          label: "매니저 계정 관리",
        },
      ],
    },
    {
      key: "6",
      icon: <img src="/images/icon13.png" className="w-[30px] h-fit" />,
      label: "게임관리",
      children: [
        {
          key: "8",
          onClick: () => handleChooseNav({menuUrl: "roomMgt", menuTitle: "진행 중"}),
          icon: (
            <img src="/images/livegame.png" className="w-[25px] h-[25px]" />
          ),
          label: "진행 중",
        },
      ],
    },
  ];

  return (
    <div
      className="lg:w-[220px] 2xl:w-[256px] h-screen"
      style={{ background: "rgba(164,105,40,0.8)" }}
    >
      <div className="flex flex-col items-center">
        <img src="/images/livegame3.png" className="w-[200px] py-[15px]" />
        <Menu
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={menuList}
        />
      </div>
    </div>
  );
};

export default Navigation;
