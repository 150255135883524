import _ from "lodash";
import { useSelector } from "react-redux";
import { getImageCardByCode } from "share/function";
import { PositionStatus } from "share/helperCommon";

const UserCard = ({ index, dataRoom }) => {
  const { showAllCard, winnerPlayer, mySeat } = useSelector(
    (state) => state.room
  );

  const renderRoleSeat = (type) => {
    switch (type) {
      case 1:
        return (
          <img
            src="/images/DealerDark.png"
            className={`lg:w-[30px] 2xl:w-[50px] h-fit`}
          />
        );
      case 2:
      case "SB":
        return (
          <img
            src="/images/sb.png"
            className={`lg:w-[30px] 2xl:w-[50px] h-fit`}
          />
        );
      case 3:
      case "BB":
        return (
          <img
            src="/images/bb.png"
            className={`lg:w-[30px] 2xl:w-[50px] h-fit`}
          />
        );
      default:
        break;
    }
  };

  const renderCard = () => {
    if (showAllCard) {
      if (dataRoom?.PositionStatus == PositionStatus.Folded) {
        return (
          <>
            <img
              src="/images/52card/lungbai.png"
              className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
            />
            <img
              src="/images/52card/lungbai.png"
              className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
            />
            <div
              // className={`absolute top-0 ${
              //   index < 4 ? "right-[30px]" : "left-[30px]"
              // }  lg:w-[50px] 2xl:w-[75px] lg:h-[70px] 2xl:h-[90px] bg-[#ffffff6c] flex items-center justify-center font-bold lg:text-[23px] 2xl:text-[30px] text-[#C83737] rounded-[10px] z-[4] mx-[10px]`}
              // style={{ transform: "translate(-50%, -50%)" }}
              className="absolute top-0 left-0 w-full h-full bg-[#ffffff6c] flex items-center justify-center font-bold lg:text-[23px] 2xl:text-[30px] text-[#C83737] rounded-[10px] z-[4]"
            >
              FOLD
            </div>
          </>
        );
      }
      return dataRoom?.card?.map((card, i) => {
        if (card.CardCode != "None" && card.CardCode != "FaceDown") {
          return (
            <img
              key={i}
              src={getImageCardByCode(card.CardCode)}
              className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
            />
          );
        }
      });
    } else {
      if (!_.isEmpty(dataRoom?.card)) {
        return dataRoom?.card?.map((card, i) => {
          if (card.CardCode != "None") {
            return (
              <img
                key={i}
                src={getImageCardByCode(card.CardCode)}
                className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
              />
            );
          } else {
            return (
              <img
                key={i}
                src="/images/52card/lungbai.png"
                className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
              />
            );
          }
        });
      }
      // else {
      //   return (
      //     <>
      //       <img
      //         src="/images/52card/lungbai.png"
      //         className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
      //       />
      //       <img
      //         src="/images/52card/lungbai.png"
      //         className="lg:w-[50px] 2xl:w-[75px] mx-[2px] z-[3]"
      //       />
      //     </>
      //   );
      // }
    }
  };

  return (
    <>
      {dataRoom?.Player && dataRoom?.HaveClient && (
        <div className="flex items-center">
          {renderRoleSeat(dataRoom?.PositionType)}
          {mySeat.PositionCode != dataRoom?.PositionCode && (
            <div className={`flex items-center relative`}>
              {renderCard()}
              {dataRoom?.PositionStatus == PositionStatus.Folded &&
                !showAllCard && (
                  <div className="absolute top-0 left-0 w-full h-full bg-[#ffffff6c] flex items-center justify-center font-bold lg:text-[23px] 2xl:text-[30px] text-[#C83737] rounded-[10px] z-[4]">
                    FOLD
                  </div>
                )}
              {/* {winnerPlayer?.findIndex(
                (wn) => wn.PositionCode == dataRoom?.PositionCode && showAllCard
              ) > -1 &&
                showAllCard && (
                  <div className="absolute bottom-[105%] left-0 w-full p-[0px_20px] box-border bg-[#ffffff6c] flex items-center justify-center font-bold lg:text-[23px] 2xl:text-[30px] text-[#FFDF00] rounded-[10px] z-[4]">
                    <span className="text-[13px] lg:text-[18px] 2xl:text-[22px] text-[red]">
                      $
                      {
                        winnerPlayer?.find(
                          (wn) => wn.PositionCode == dataRoom?.PositionCode
                        )?.moneyWinner
                      }
                    </span>
                  </div>
                )} */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserCard;
