import React, { createContext, useEffect, useState } from "react";
import { LoadingProvider } from "./loading/LoadingProvider";
import Loading from "./loading";
import { Toaster } from "react-hot-toast";
import useGetUserById from "main/admin/userMgt/hook/useGetUserById";
import { decodeToken } from "share/function";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "redux/action/homeAction";

export const IsMobileContext = createContext(null);

const LayoutMaster = ({ component }) => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  window.addEventListener("resize", () => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  const {data: dataUser, fetch: fetchUser} = useGetUserById(decodeToken(localStorage.getItem('jwt'))?.uid);

  useEffect(()=> {
    if(dataUser) {
      dispatch(updateUserInfo(dataUser))
    }
  }, [dataUser])

  return (
    <IsMobileContext.Provider value={{ isMobile: isMobile, fetchUser: fetchUser }}>
      <LoadingProvider>
        <div
          className="overflow-x-hidden w-screen h-screen bg-cover"
          style={{ backgroundImage: "url('/images/1353374.jpeg')" }}
        >
          <div className="w-full h-full backdrop-blur-md bg-white/60">
            {component}
          </div>
        </div>
        <Loading />
        <Toaster position="top-center" reverseOrder={false} gutter={8}/>
      </LoadingProvider>
    </IsMobileContext.Provider>
  );
};

export default LayoutMaster;
