import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserJoin from "./components/userJoin";
import ToolbarRoom from "./components/toolbarRoom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelGame,
  changeTurn,
  clearMyTurn,
  clearTurnRoomInfo,
  endGame,
  exitRoom,
  haveANewRound,
  joinRoom,
  leaveRoom,
  receiveCardOnSeat,
  reconnectGame,
  resetGame,
  setBetOnSeatReserved,
  showPlayerHandRank,
  startGame,
  updateBetOnSeat,
  updateCaptureCard,
  updateChat,
  updateChatOnRoom,
  updateCheckSatus,
  updateClientOnSeat,
  updateFirstBetOnSeat,
  updateMySeat,
  updateReaction,
  updateRoom,
  updateSeatAtPosition,
  updateSeatNextTurn,
  updateSeatOnRoom,
  updateShowCallCard,
  updateUserInfoOnRoom,
} from "redux/action/roomAction";
import useGetRoomDetail from "main/admin/roomMgt/hook/useGetRoomDetail";
import toast from "react-hot-toast";
import _ from "lodash";
import useLoadingEffect from "fuse/layout/layout-master/hook/useLoadingEffect";
import ShowCard from "./components/showCard/ShowCard";
import EmojiBoard from "./components/toolbarRoom/components/emoji/EmojiBoard";
import StartGame from "./components/screen/StartGame";
import { updateUserInfo } from "redux/action/homeAction";
import SeatCard from "./components/SeatCard";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import BuyInPopupV2 from "./components/popup/BuyInPopupV2";
import { POPUP_ID } from "share/constantShared";
import ChatArea from "./components/chatArea";
import PopupChangeNickName from "./components/toolbarRoom/components/soundSetting/components/PopupChangeNickName";
import { updateCameraStream, updateNewChat } from "redux/action/toolbarAction";
import CaptureCard from "./components/captureCard/CaptureCard";
import { decodeToken } from "share/function";
import { SocketUserProvider } from "fuse/layout-user/layout-master";

const RoomMain = () => {
  const { socket: socketClient } = useContext(SocketUserProvider);
  const [searchParams, setSearchParams] = useSearchParams();
  const [socket, setSocket] = useState(null);
  const [socket2, setSocket2] = useState(null);
  let idRoom = searchParams.get("id");
  let timer;
  let timerTryReconnect;
  var pc = null;
  const [timeGameStart, setTimeGameStart] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const winnerRef = useRef(null);

  const {
    roomInfo,
    gameStart,
    showAllCard,
    mySeat,
    totalBet,
    optionToolbar,
    winnerPlayer,
  } = useSelector((state) => state.room);

  const { userInfo } = useSelector((state) => state.home);
  const { music, cameraStream } = useSelector((state) => state.toolbar);
  const [isOnline, setIsOnline] = useState({
    online: true,
    reConnect: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let interval = null;

  const InternetErrMessagenger = () => {
    if (navigator.onLine) {
      if (isOnline.reConnect) {
        if (socket) {
          socket.close();
        }
        // let socket = new WebSocket("ws://192.168.100.66:8080");
        let socketB = new WebSocket(dataRoomAPI?.roomUrl);
        setSocket(socketB);
        setIsOnline({
          online: navigator.onLine === true,
          reConnect: false,
        });
      }
    } else {
      // socket?.close();
      setIsOnline({
        online: navigator.onLine === true,
        reConnect: true,
      });
    }
  };

  useEffect(() => {
    interval = setInterval(InternetErrMessagenger, 500);
    return () => {
      clearInterval(interval);
    };
  }, [isOnline]);

  const {
    data: dataRoomAPI,
    isLoading: isLoadingRoom,
    fetch: fetchData,
  } = useGetRoomDetail(idRoom);

  useEffect(() => {
    if (!_.isEmpty(dataRoomAPI)) {
      if (socket) {
        socket.close();
      }
      if (!socket) {
        setIsLoading(true);
        let socket = new WebSocket(dataRoomAPI?.roomUrl);
        // let socket = new WebSocket("ws://192.168.100.66:8080");
        setSocket(socket);
      }
    }
  }, [dataRoomAPI]);

  const tryReconnect = () => {
    timerTryReconnect = setTimeout(() => {
      // let socket = new WebSocket("ws://192.168.100.66:8080");
      let socket = new WebSocket(dataRoomAPI?.roomUrl);
      setSocket(socket);
    }, 1000);
  };

  useEffect(() => {
    if (socket) {
      socket.onclose = (event) => {
        // console.log("event", event);
        if (event.reason == "the player leaves the room") {
          music.Main.pause();
          return navigate("/");
        }
        if (event.code == 1006) {
          setIsLoading(true);
          tryReconnect();
        }
      };
      socket.onopen = async (e) => {
        clearTimeout(timerTryReconnect);
        let user = decodeToken(localStorage.getItem("jwt"));
        const response = await fetch("https://api.ipify.org?format=json");
        const myIP = await response.json();
        if (user) {
          socket.send(
            JSON.stringify({
              FunctionCode: "JoinRoom",
              FFunctionCode: "",
              Data: {
                userName: user?.userName,
                nickName: user?.nickName,
                uid: user?.uid,
                PlayerType: user?.roleId,
                TotalAmount: user?.accountBalance,
                ipUser: myIP?.ip ?? "",
                PriceOnTable: 0,
              },
              Info: null,
              OData: null,
            })
          );
        }
      };
      socket.onmessage = function (evt) {
        const result = JSON.parse(evt.data);
        console.log("result", result);
        switch (result.FFunctionCode) {
          case "JoinRoom": {
            switch (result.FunctionCode) {
              case "Completed": {
                let user = decodeToken(localStorage.getItem("jwt"));
                dispatch(joinRoom({ userInfo: userInfo ?? user }));
                socketClient.send(
                  JSON.stringify({
                    FunctionCode: "join_room",
                    Data: {
                      Player: {
                        uid: user.uid,
                      },
                    },
                  })
                );
                setIsLoading(false);
                music.Main.volume = 0.1;
                music.Main.pause();
                music.Main.play();
                break;
              }
              case "Error": {
                toast.error("Can't join this room", 2000);
                setTimeout(() => {
                  socket.send(
                    JSON.stringify({
                      FunctionCode: "Exit",
                      FFunctionCode: "",
                      Data: null,
                    })
                  );
                  music.Main.pause();
                  navigate("/");
                }, 2000);
                break;
              }
              case "SyncAllSeat": {
                dispatch(
                  updateRoom(
                    result?.Data?.map((seat) => {
                      return { ...seat, card: [], mySeat: false, turn: {}, Player: {...seat.Player, TotalMoney: seat?.Player?.TotalAmount - seat?.Player?.PriceOnTable} };
                    })
                  )
                );
                break;
              }
              case "SyncSeat": {
                dispatch(
                  updateClientOnSeat({
                    Client: {
                      PositionCode: result.Data.PositionCode,
                      PositionStatus: result.Data.PositionStatus,
                    },
                  })
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "ReConnect": {
            switch (result.FunctionCode) {
              case "Completed": {
                dispatch(joinRoom({ userInfo: userInfo, reConnect: true }));
                setIsLoading(false);
                music.Main.volume = 0.1;
                music.Main.pause();
                music.Main.play();
                break;
              }
              default:
                break;
            }
            break;
          }
          case "Call": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                music.Call.play();
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                dispatch(updateBetOnSeat(result.Data));
                dispatch(updateSeatNextTurn({ ...result.Data }));
                break;
              }
              default:
                break;
            }
            break;
          }
          case "Check": {
            switch (result.FunctionCode) {
              case "Check":
              case "SyncSeat": {
                music.Check.play();
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                dispatch(updateBetOnSeat(result.Data));
                dispatch(
                  updateSeatNextTurn({ ...result.Data, option: "Check" })
                );
                dispatch(updateCheckSatus(true));
                break;
              }
              default:
                break;
            }
            break;
          }
          case "Fold": {
            switch (result.FunctionCode) {
              case "Fold": {
                // setTimeGameStart(dataRoom?.roomTime);
                // clearInterval(timer);

                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                break;
              }
              case "SyncSeat": {
                // musicFold.play();
                music.CFold.play();
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                dispatch(updateBetOnSeat({ ...result.Data, option: "Fold" }));
                dispatch(updateSeatNextTurn({ ...result.Data }));
                break;
              }
              default:
                break;
            }
            break;
          }
          case "TakeSeat": {
            dispatch(updateSeatOnRoom(result.Data));
            // dispatch(updateUserInfo(dataUser)) đang làm ở đây
            break;
          }
          case "Raise": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                music.Raise.play();
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                dispatch(updateBetOnSeat(result.Data));
                dispatch(updateSeatNextTurn({ ...result.Data }));
                break;
              }
              default:
                break;
            }
            break;
          }
          case "AllIn": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                music.AllIn.play();
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                dispatch(updateCheckSatus(true));
                dispatch(updateBetOnSeat(result.Data));
                dispatch(updateSeatNextTurn({ ...result.Data }));
                break;
              }
              default:
                break;
            }
            break;
          }
          case "ChangeSeat": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                dispatch(
                  updateSeatOnRoom({
                    ...result.Data,
                    isChanged: true,
                    OData: result.OData,
                  })
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "SendEmotion": {
            switch (result.FunctionCode) {
              case "SyncData": {
                dispatch(updateReaction({ ...result.Data, status: "insert" }));
                setTimeout(() => {
                  dispatch(
                    updateReaction({ ...result.Data, status: "remove" })
                  );
                }, 5000);
                break;
              }
              default:
                break;
            }
            break;
          }
          case "ChangeNickName": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                dispatch(
                  updateSeatAtPosition({
                    ...result.Data,
                    changePlayerInfo: true,
                  })
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "SendMessage": {
            switch (result.FunctionCode) {
              case "SyncData": {
                dispatch(
                  updateChatOnRoom({
                    ...result.Data,
                    Status: true,
                  })
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          case "CaptureCard": {
            switch (result.FunctionCode) {
              case "CaptureCard": {
                // dispatch(
                //   updateChatOnRoom({
                //     ...result.Data,
                //     Status: true,
                //   })
                // );
                dispatch(updateCaptureCard({ status: true }));
                const img = document.querySelector("#captureCard1");
                // const img2 = document.querySelector("#captureCard2");
                img.src = `data:image/jpeg;base64,${result.Data.Card1}`;
                // img2.src = `data:image/jpeg;base64,${result.Data.Card2}`;
                break;
              }
              default:
                break;
            }
            break;
          }
          case "Reserved": {
            switch (result.FunctionCode) {
              case "SyncSeat": {
                dispatch(updateMySeat(result.Data));
                break;
              }
              default:
                break;
            }
            break;
          }
          default: {
            switch (result.FunctionCode) {
              case "TakeSeat": {
                let socket2 = new WebSocket(dataRoomAPI?.streamVideoUrl);
                // let socket2 = new WebSocket("ws://192.168.100.66:8081");
                setSocket2(socket2);
                dispatch(updateSeatOnRoom({ ...result.Data, mySeat: true }));
                break;
              }
              case "SyncSeat": {
                dispatch(updateSeatOnRoom({ ...result.Data }));
                break;
              }
              case "ReceiveCard": {
                music.Throwing.pause();
                music.Throwing.play();
                dispatch(receiveCardOnSeat(result.Data));
                break;
              }
              case "GambleNew": {
                if (document.querySelector("#winnerTag")) {
                  document.querySelector("#winnerTag").style.display = "none";
                  document.querySelector("#winnerTag").setAttribute("flag", 1);
                }
                handleStartGame();
                music.StartGame.volume = 0.3;
                music.StartGame.play();
                dispatch(startGame());
                result.Data?.forEach((seat) => {
                  if (!_.isEmpty(seat)) {
                    dispatch(
                      updateSeatAtPosition({
                        PositionCode: seat.PositionCode,
                        PositionType: seat.PositionType,
                        PositionStatus: seat.PositionStatus,
                        Player: {...seat.Player, TotalMoney: seat.Player.TotalAmount - seat.Player.PriceOnTable},
                        turn: {},
                      })
                    );
                  }
                });

                // music1.volume = 50;
                break;
              }
              case "FirstBet": {
                dispatch(updateFirstBetOnSeat(result.Data));
                break;
              }
              case "YourTurn": {
                dispatch(updateCheckSatus(true));
                dispatch(changeTurn(result.Data));
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                handlePlayGame();
                break;
              }
              case "WinnerPlayer": {
                // setIsLoading(false);
                document.querySelector("#winnerTag").setAttribute("flag", 0);
                handleEndGame();
                dispatch(endGame(result.Data));
                music.Winner.volume = 0.5;
                music.Winner.play();
                break;
              }
              case "AllPlayerHandRank": {
                dispatch(showPlayerHandRank(result.Data));
                break;
              }
              case "PlayerOutRoom": {
                dispatch(updateSeatOnRoom(result.Data));
                break;
              }
              case "ReceiveMoney": {
                dispatch(updateClientOnSeat(result.Data));
                break;
              }
              case "Disconnect": {
                dispatch(
                  updateClientOnSeat({
                    Client: {
                      PositionCode: result.Data,
                      PositionStatus: 7,
                    },
                  })
                );
                break;
              }
              case "WaitingCheckingWinner": {
                dispatch(haveANewRound());
                setTimeGameStart(dataRoom?.roomTime);
                clearInterval(timer);
                // setIsLoading(true);
                break;
              }
              case "ResetGame": {
                setIsLoading(false);
                dispatch(resetGame());
                break;
              }
              case "Timeout": {
                dispatch(clearMyTurn());
                break;
              }
              case "SendTotalMoney": {
                dispatch(updateUserInfoOnRoom({ accountBalance: result.Data }));
                dispatch(updateUserInfo({ accountBalance: result.Data }));
                break;
              }
              case "ReceiveAllCards": {
                Object.keys(result.Data?.ListPlayerCard).forEach((key) => {
                  result.Data?.ListPlayerCard[key].forEach((card, i) => {
                    if (card.CardCode != "None") {
                      dispatch(
                        receiveCardOnSeat({
                          CardSetupInfo: card,
                          PositionCode: key,
                          ShowCardEndGame: true,
                        })
                      );
                    }
                  });
                });
                dispatch(updateShowCallCard());
                break;
              }
              case "CancelGame": {
                dispatch(cancelGame(result.Data));
                break;
              }
              case "ReConnect": {
                handlePlayGame(result.Data.TimeAction);
                dispatch(reconnectGame(result.Data));
                if (result.Data.YourPosCode > 0) {
                  if(socket2) {
                    socket2.close();
                  }
                  let socketB = new WebSocket(dataRoomAPI?.streamVideoUrl);
                  // let socket2 = new WebSocket("ws://192.168.100.66:8081");
                  setSocket2(socketB);
                }
                break;
              }
              case "ResetCamera": {
                let socketCamera = new WebSocket(dataRoomAPI?.streamVideoUrl);
                // let socketCamera = new WebSocket("ws://192.168.100.66:8081");
                setSocket2(socketCamera);
              }
              case "NewRound": {
                dispatch(haveANewRound());
              }
              default:
                break;
            }
          }
        }
      };
    }
  }, [socket]);

  const createPCRTC = () => {
    const config = {
      iceServers: !_.isEmpty(dataRoomAPI?.rTCIceServer)
        ? JSON.parse(dataRoomAPI?.rTCIceServer)?.map((cf, index) => {
            return {
              urls: cf?.Url,
              ...(cf?.UserName && { username: cf?.UserName }),
              ...(cf?.Password && { credential: cf?.Password }),
            };
          })
        : [],
      iceTransportPolicy: dataRoomAPI?.rTCIceTransportPolicy,
    };

    pc = new RTCPeerConnection(config);

    function handleReconnection() {
      pc.restartIce();
    }

    function sendIceCandidate(candidate) {
      // Chỉ gửi srflx hoặc relay candidates
      const candidateType = candidate.candidate.split(" ")[7]; // Loại candidate nằm ở vị trí thứ 7
      if (candidateType === "srflx" || candidateType === "relay") {
        // Gửi candidate này qua signaling
        //signalingChannel.send(JSON.stringify(candidate));
        socket2.send(
          JSON.stringify({
            type: "candidate",
            candidate: candidate.candidate,
            sdpMid: candidate.sdpMid,
            sdpMLineIndex: candidate.sdpMLineIndex,
          })
        );
      }
    }

    pc.ontrack = (evt) => {
      setIsLoading(false);
      if (winnerRef?.current) {
        winnerRef.current.srcObject = evt.streams[0];
      }
    };

    // Xử lý ICE candidates
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        sendIceCandidate(event.candidate);
      }
    };
    // Xử lý thay đổi trạng thái ICE connection
    pc.oniceconnectionstatechange = () => {
      if (
        pc.iceConnectionState === "disconnected" ||
        pc.iceConnectionState === "failed"
      ) {
        handleReconnection();
      }
    };

    return pc;
  };


  useEffect(() => {
    if (socket2) {
      createPCRTC();
      socket2.onmessage = async function (evt) {
        if (/^[\{"'\s]*candidate/.test(evt.data)) {
          pc.addIceCandidate(JSON.parse(evt.data));
        } else {
          await pc.setRemoteDescription(
            new RTCSessionDescription(JSON.parse(evt.data))
          );
          pc.createAnswer()
            .then((answer) => pc.setLocalDescription(answer))
            .then(() => socket2.send(JSON.stringify(pc.localDescription)));
        }
      };

      pc.oniceconnectionstatechange = () => {
        // console.log("chạy vào", pc.iceConnectionState);
        if (
          pc.iceConnectionState === "disconnected" ||
          pc.iceConnectionState === "failed"
        ) {
          createPCRTC();
        }
      };
    }
  }, [socket2]);

  useEffect(() => {
    if (optionToolbar.mutate) {
      music.Main.muted = !music.Main.muted;
    }
  }, [optionToolbar]);

  useEffect(() => {
    music.Main.onended = () => {
      music.Main.play();
    };

    function hideError(e) {
      if (
        e.message ===
          "ResizeObserver loop completed with undelivered notifications." ||
        e.message ===
          "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);

    return () => {
      window.addEventListener("error", hideError);
      if (socket) {
        socket.close();
      }
      music.Main.pause();
      dispatch(exitRoom());
      clearInterval(interval);
      window.removeEventListener("popstate", () => {});
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", function (e) {
      if (socket) {
        socket.close();
      }
    });
  });

  const dataRoom = useMemo(() => {
    if (!_.isEmpty(roomInfo) && !_.isEmpty(dataRoomAPI)) {
      return {
        ...dataRoomAPI,
        roomPlayer: roomInfo,
        roomMember: roomInfo,
      };
    }
  }, [roomInfo, dataRoomAPI]);

  const handleStartGame = () => {
    if (document.querySelector("#startGame")) {
      document.querySelector("#startGame").style.display = "block";
    }
    setTimeout(() => {
      if (document.querySelector("#startGame")) {
        document.querySelector("#startGame").style.display = "none";
      }
    }, 3000);
  };

  const handlePlayGame = (timeAction = 0) => {
    if (timeAction > 0) {
      setTimeGameStart(timeAction - 1);
    }
    let seconds = timeAction > 0 ? timeAction - 1 : dataRoom?.roomTime ?? 15;

    timer = setInterval(() => {
      seconds--;
      setTimeGameStart(seconds);

      if (seconds < 0) {
        setTimeGameStart(dataRoom?.roomTime);
        clearInterval(timer);
      }
    }, 1000);
  };

  const handleEndGame = useCallback(() => {
    dispatch(clearTurnRoomInfo());
  }, [document?.querySelector("#winnerTag")?.getAttribute("flag")]);

  const handleTakeSeat = (data) => {
    socket.send(
      JSON.stringify({
        FunctionCode: "Reserved",
        FFunctionCode: "",
        Data: parseFloat(data.price),
      })
    );
    dispatch(setBetOnSeatReserved(parseFloat(data.price)));
  };

  const handleLogo = () => {
    dispatch(leaveRoom());
    socket.close();
    if (socket2) {
      socket2.close();
    }
    if (_.isEmpty(mySeat)) {
      socketClient.send(
        JSON.stringify({
          FunctionCode: "PlayerOutRoom",
          Data: {
            Player: userInfo,
          },
        })
      );
    }
    music.Main.pause();
    winnerRef.current.srcObject = null;
    window.location.replace("/");
  };

  const toggleBuyIn = () => {
    dispatch(openPopup({ id: POPUP_ID.BUY_IN }));
  };

  const handleRefresh = () => {
    socket.close();
    setTimeout(() => {
      let socketRefresh = new WebSocket(dataRoomAPI?.roomUrl);
      // let socketRefresh = new WebSocket("ws://192.168.100.66:8080");
      setSocket(socketRefresh);
    }, 500);
  };

  const handleChangeCameraStream = () => {
    winnerRef.current.pause();
    let bgBlur = document.querySelector("#bg-blur");
    bgBlur.style.display = "block";
    socket2.send(
      JSON.stringify({
        FunctionCode: "ChangeCamera",
        Data: cameraStream == "Cam1" ? "Cam2" : "Cam1",
      })
    );
    setTimeout(() => {
      winnerRef.current.play();
      bgBlur.style.display = "none";
    }, 3000);
    dispatch(updateCameraStream(cameraStream == "Cam1" ? "Cam2" : "Cam1"));
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  useLoadingEffect(isLoading || isOnline.reConnect);

  return (
    <div className="flex items-center h-screen w-screen overflow-hidden relative">
      <div className="relative w-full h-full overflow-hidden" id="mainRoom">
        <video
          ref={winnerRef}
          id="videoCtl"
          autoPlay
          playsInline
          muted
          preload="auto"
          onContextMenu={handleContextMenu}
          onDoubleClick={(e) => {
            e.preventDefault();
          }}
          onClick={(e) => {
            e.preventDefault();
          }}
          src=""
          className="w-full h-fit lg:h-full absolute object-fill top-0 left-0 z-[1]"
        />
        <div
          className="w-full h-fit lg:h-full absolute top-0 left-0 z-[2] backdrop-blur-2xl bg-white/30"
          style={{ display: "none" }}
          id="bg-blur"
        ></div>
        <div className="flex justify-between z-[3] relative lg:p-[5px] 2xl:p-[10px]">
          <div className="grid grid-cols-1 lg:gap-[20px] 2xl:gap-[30px]">
            <div className="flex items-center w-fit">
              <div onClick={handleLogo} className="cursor-pointer">
                <img
                  src="/images/close.png"
                  className="w-[60px] lg:ml-[15px] 2xl:ml-[20px]"
                />
              </div>
              <div
                className="lg:p-[3px_7px] 2xl:p-[5px_10px] lg:min-w-[80px] 2xl:min-w-[100px] flex flex-col items-center rounded-[10px] border-[2px] border-solid border-[#FFDF00] lg:mx-[15px] 2xl:mx-[20px]"
                style={{
                  textShadow: "0 20px 20px #C83737",
                  backgroundColor: "rgba(119,119,119,0.7)",
                }}
              >
                <div className="flex items-center justify-between">
                  <img
                    src="/images/livegame.png"
                    className="lg:w-[25px] 2xl:w-[30px]"
                  />
                  <span className="text-[#FFDF00] italic font-bold text-[11px] lg:text-[13px] 2xl:text-[17px]">
                    {dataRoom?.roomName}
                  </span>
                </div>
                <span className="text-hightlight text-[13px] lg:text-[15px] 2xl:text-[18px]">
                  ${dataRoom?.roomPrice}
                </span>
              </div>
              <div className="lg:mx-[15px] 2xl:mx-[20px]">
                <UserJoin
                  data={mySeat}
                  avatar
                  setOpen={toggleBuyIn}
                  socket={socket}
                  timeGameStart={timeGameStart}
                />
              </div>
            </div>
            {(gameStart || showAllCard) && <ShowCard />}
          </div>
          <ToolbarRoom
            handleRefresh={handleRefresh}
            handleChangeCameraStream={handleChangeCameraStream}
          />
        </div>
        {
          <SeatCard
            socket={socket}
            data={dataRoom?.roomPlayer}
            timeGameStart={timeGameStart}
            toggleBuyIn={toggleBuyIn}
          />
        }
        <div
          className="absolute bottom-[130px] lg:bottom-[170px] 2xl:bottom-[240px] left-[50%] z-[3] flex flex-col items-center justify-center"
          style={{ transform: "translateX(-50%)" }}
        >
          {showAllCard && winnerPlayer?.length > 0 && (
            <div
              className={`flex flex-col items-center justify-center lg:min-w-[130px] xl:min-w-[175px] 2xl:min-w-[250px] w-fit mb-[10px] rounded-[10px] bg-center bg-no-repeat  ${
                winnerPlayer[0]?.HandRank?.handRankType == 1
                  ? "lg:h-[70px] xl:h-[80px] 2xl:h-[100px] lg:p-[15px] 2xl:p-[20px]"
                  : "p-[7px_10px] lg:p-[8px_15px] 2xl:p-[10px_20px] rounded-[10px] border-[2px] border-solid border-[#FFDF00] h-fit"
              }`}
              style={{
                background: `${winnerPlayer[0]?.HandRank?.handRankType == 2 ? "rgba(119,119,119,0.7)": 'unset'}`,
                backgroundImage: `${
                  winnerPlayer[0]?.HandRank?.handRankType == 1 ? "url('/images/reason/handrank1.png')" : 'unset'
                }`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            >
              {winnerPlayer[0]?.HandRank?.handRankType == 2 ? (
                <div className="grid grid-cols-1 lg:gap-[7px] 2xl:gap-[10px] text-center">
                  <span
                    className="border-[#FFDF00] text-center text-[#fff] lg:text-[15px] 2xl:text-[28px] font-bold"
                    style={{ textShadow: "rgb(105 103 103) 3px 2px" }}
                  >
                    {winnerPlayer?.map((wn, index) => {
                      if (index == winnerPlayer?.length - 1) {
                        return `${wn?.Player?.nickName?.toUpperCase()}`;
                      }
                      return `${wn?.Player?.nickName?.toUpperCase()}, `;
                    })}{" "}
                    WIN
                  </span>
                  <span
                    className="border-[#FFDF00] text-center text-[#fff] lg:text-[13px] 2xl:text-[18px] font-bold"
                    style={{ textShadow: "rgb(105 103 103) 3px 2px" }}
                  >
                    {winnerPlayer[0]?.HandRank?.handRankName?.toUpperCase()}
                  </span>
                </div>
              ) : (
                <span
                  className="border-[#FFDF00] text-center text-[#fff] lg:text-[16px] 2xl:text-[28px] font-bold"
                  style={{ textShadow: "rgb(105 103 103) 3px 2px" }}
                >
                  {winnerPlayer[0]?.HandRank?.handRankName?.toUpperCase()}
                </span>
              )}
            </div>
          )}

          <div
            className="lg:w-[120px] lg:h-[70px] xl:w-[170px] xl:h-[90px] 2xl:w-[180px] 2xl:h-[100px] bg-no-repeat lg:bg-cover 2xl:bg-contain flex flex-col items-center justify-center lg:text-[20px] 2xl:text-[28px]"
            style={{
              backgroundImage: "url('/images/newPot2.png')",
            }}
          >
            <span
              style={{ transform: "translateY(-5px)" }}
              className="text-hightlight"
            >
              ${totalBet?.toLocaleString()}
            </span>
          </div>
        </div>
        <div id="winnerTag"></div>
        <StartGame />
        <CaptureCard />
      </div>
      <ChatArea socket={socket} />
      <EmojiBoard socket={socket} id={POPUP_ID.EMOJI} />
      <BuyInPopupV2
        id={POPUP_ID.BUY_IN}
        handleTakeSeat={handleTakeSeat}
        data={userInfo}
      />
      <PopupChangeNickName socket={socket} />
    </div>
  );
};

export default RoomMain;
