import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSignup } from "./hook/useSignup";
import toast from "react-hot-toast";
import ConfirmEmail from "./components/ConfirmEmail";
import { openPopup } from "redux/action/custom-popup/customPopupAction";
import { popupIdSignUp } from "./constant";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useSignup();
  const method = useForm({
    mode: "onSubmit",
    defaultValues: {
      userName: "",
      password: "",
      cfPassword: "",
      phone: "",
      mail: "",
      role: 1,
      nickName: "",
      accountBalance: 0,
    },
    resolver: yupResolver(
      yup.object().shape({
        userName: yup.string().required(),
        password: yup.string().required(),
        cfPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password")], "Not match password"),
        phone: yup.number().required(),
        mail: yup.string().email().required(),
        nickName: yup.string().required(),
      })
    ),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = method;

  const handleSubmitForm = async (data) => {
    const dataSubmit = {
      ...data,
      accountBalance: 1000,
      phone: data.phone && data.phone?.toString(),
    };
    await mutate({
      ...dataSubmit,
      onSuccess: (msg) => {
        toast.success("Signup successfull");
        dispatch(openPopup({ id: popupIdSignUp.confirmEmail }));
      },
      onError: (msg) => {
        toast.error(msg.message);
      },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // useLoadingEffect(isLoading);

  return (
    <div>
      <div className="flex flex-col items-center justify-top w-full h-full">
        <img
          src="/images/livegame.png"
          className="h-fit w-[180px] m-[30px_0px_50px_0px]"
        />
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div
              className="rounded-[10px] border-[2px] border-solid border-[#FFDF00] p-[20px_40px_40px_40px] flex flex-col items-center w-[40vw] h-fit"
              style={{ backgroundColor: "rgba(119,119,119,0.7)" }}
            >
              <h4
                className="text-[#FFDF00] text-[30px] border-solid border-[#FFDF00] mb-[20px] font-normal"
                style={{ borderWidth: "0px 0px 2px 0px" }}
              >
                SIGN UP(등록)
              </h4>
              <div className="grid grid-cols-2 lg:gap-[20px] 2xl:gap-[40px] lg:mt-[20px] 2xl:mt-[40px] w-full">
                <Controller
                  name="userName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="ID(아이디)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                    />
                  )}
                />
                <Controller
                  name="nickName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Nickname(닉네임)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                    />
                  )}
                />
                {/* <div className="h-[50px]">
                  <p className="text-[16px] text-[#fff]">{}</p>
                </div> */}
              </div>
              <div className="grid grid-cols-2 lg:gap-[20px] 2xl:gap-[40px] lg:mt-[20px] 2xl:mt-[40px] w-full">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input.Password
                      {...field}
                      placeholder="PW(비밀번호)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  )}
                />
                <Controller
                  name="cfPassword"
                  control={control}
                  render={({ field }) => (
                    <Input.Password
                      {...field}
                      placeholder="Confirm PW(비밀번호 확인)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  )}
                />
              </div>
              <div className="grid grid-cols-2 lg:gap-[20px] 2xl:gap-[40px] lg:mt-[20px] 2xl:mt-[40px] w-full">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Phone number(휴대번호)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                    />
                  )}
                />
                <Controller
                  name="mail"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      placeholder="Email(이메일)"
                      className="lg:p-[10px] 2xl:p-[15px]"
                    />
                  )}
                />
              </div>
              <div className="grid grid-cols-2 lg:gap-[20px] 2xl:gap-[40px] lg:mt-[20px] 2xl:mt-[40px]">
                <button
                  type="button"
                  className="cursor-pointer w-[200px] p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#C83737] text-[#fff] flex items-center justify-center"
                  onClick={handleCancel}
                >
                  Cancel (취소)
                </button>
                <button
                  type="submit"
                  className="cursor-pointer w-[200px] p-[15px_10px] rounded-[30px] border-none outline-none m-[0_auto] bg-[#FFDF00] flex items-center justify-center"
                >
                  Sign up(등록)
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <ConfirmEmail />
    </div>
  );
};

export default SignUp;
