import React from "react";
import { CustomPopup } from "share/components";

const GameGuidePopup = ({ id }) => {
  return (
    <CustomPopup id={id} className="no-padding" closeIcon={false}>
      <div className="bg-[#13563B] p-[10px] lg:p-[20px] rounded-[10px] w-[90vw] lg:w-[70vw] 2xl:w-[50vw]">
        <h5 className="text-[#FFDF00] font-bold text-[14px] lg:text-[20px] 2xl:text-[25px] mb-[10px] lg:mb-[15px]">GUIDE</h5>
        <p className="text-[#fff] text-[11px] lg:text-[15px] 2xl:text-[18px]">
          Texas Hold'em là một biến thể phổ biến của trò chơi poker tiêu chuẩn.
          Nó xuất phát vào đầu những năm 1900 tại Robstown, Texas, Hoa Kỳ, nơi
          nó được chơi lần đầu trong một trò chơi bài địa phương có tên "Hold Me
          Darling." Sau đó, trò chơi di cư đến thành phố Dallas và trở nên nổi
          tiếng với tên Texas Hold'em. Các quy tắc cơ bản của Texas Hold'em rất
          đơn giản. Mỗi người chơi được chia hai lá bài riêng, gọi là "hole
          card", mà họ phải giữ bí mật với những người chơi khác. Sau đó, sẽ có
          5 lá bài chung (community card) được chia và được lật trên bàn chơi,
          mà tất cả người chơi đều có thể sử dụng để tạo nên tay bài tốt nhất
          của họ. Mục tiêu của trò chơi là tạo ra tay bài 5 lá tốt nhất có thể
          bằng cách sử dụng bất kỳ sự kết hợp nào của 2 lá bài riêng và 5 lá bài
          chung. Sau lượt chia bài ban đầu, có một vòng cược. Sau đó, 3 lá bài
          chung được chia sẽ được lật trên bàn, tiếp theo là một vòng cược khác.
          Quá trình này lặp lại với lá bài chung thứ 4 và thứ 5, với một vòng
          cược sau khi mỗi lá bài được chia. Người chơi có thể chọn bỏ cuộc
          (fold), theo (call), hoặc tăng cược (raise) trong mỗi vòng cược.
        </p>
      </div>
    </CustomPopup>
  );
};

export default GameGuidePopup;
